export const ProfileData={
    brandName:'MaKK Zone',
    name: 'Manojkumar Kotakonda',
    topSkills:['Generative AI','AI Agents','Full Stack Developer','AWS','Java','Python'],
    headLine:'Generative AI | AI Agents | Full Stack Developer | AWS | Java | Python',
    intro:'I am a dedicated software engineer who loves working with artificial intelligence and machine learning. I have strong skills in full stack development and problem-solving, which help me build creative software solutions using AI and ML. My aim is to use my abilities to work on exciting projects that advance technology and benefit society.',
    linkedIn:'https://www.linkedin.com/in/makkzone/',
    blogLink:'https://medium.com/@makkzone',
    gitHubLink:'https://github.com/makkzone',
    certifications:[{
        logo:'../assets/aws-certified-developer-associate.png',
        name:'AWS Certified Developer – Associate',
        issuedBy:'Amazon Web Services Training and Certification',
        linkToCertification:'https://www.credly.com/badges/aebb6984-0417-4042-9191-273636b1e6f3/public_url'
    }],
    skills:[]
}