import { FaGithub, FaLinkedin, FaMediumM } from "react-icons/fa";
import { ProfileData } from "./data/profile-data";
export const Routes = [
  {
    path: ProfileData.blogLink,
    name: "Blog",
    icon: <FaMediumM/>,
    external:true,
    layout: "/public",
  },
  {
    path: ProfileData.linkedIn,
    name: "LinkedIn",
    icon: <FaLinkedin/>,
    external:true,
    layout: "/public",
  },
  {
    path: ProfileData.gitHubLink,
    name: "GitHub",
    icon: <FaGithub/>,
    external:true,
    layout: "/public",
  }
];
