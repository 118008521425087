import { Stack, useBreakpointValue } from '@chakra-ui/react';
import { Container } from '@cloudscape-design/components';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { IN,AR,US,CA } from 'country-flag-icons/react/3x2'

function calculateDurationInMonths(startDate, endDate) {
    
    // Extract the year and month from the start and end dates
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth(); // Months are zero-indexed in JavaScript
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();

    // Calculate the difference in years and months
    const yearDifference = endYear - startYear;
    const monthDifference = endMonth - startMonth;

    // Calculate the total number of months
    const totalMonths = yearDifference * 12 + monthDifference;

    return totalMonths;
}

export default function Experience (){
    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
      })
    
    // Define the start date (October 2023)
    const startDate = new Date('2023-10-01');
    // Get today's date
    const endDate = new Date(); // This will automatically take today's date
    const durationInMonths = calculateDurationInMonths(startDate, endDate);
    const experience=[{
        title:'Technical Lead',
        location: 'Halifax, NS, Canada',
        timeline: 'Oct 2023 - Present',
        duration:`${durationInMonths} months`,
        locationIcon:<CA style={{width:'15px'}}/>
    },{
        title:'Technical Lead',
        location: 'Fortwayne, Indiana, United States',
        timeline: 'Jan 2022 - Sep 2023',
        duration:'1 year 9 months',
        locationIcon:<US style={{width:'15px'}}/>
    },{
        title:'Associate - Projects',
        location: 'Fortwayne, Indiana, United States',
        timeline: 'Oct 2018 - Dec 2021',
        duration:'3 years 3 months',
        locationIcon:<US style={{width:'15px'}}/>
    },{
        title:'Associate - Projects',
        location: 'Buenos Aires Province, Argentina',
        timeline: 'Mar 2016 - Sep 2018',
        duration:'2 years 7 months',
        locationIcon:<AR style={{width:'15px'}}/>
    },{
        title:'Programmer Analyst',
        location: 'Buenos Aires Province, Argentina',
        timeline: 'Dec 2015 - Feb 2016',
        duration:'3 months',
        locationIcon:<AR style={{width:'15px'}}/>
    },{
        title:'Programmer Analyst',
        location: 'Chennai, Tamil Nadu, India',
        timeline: 'Jun 2014 - Nov 2015',
        duration:'1 year 6 months',
        locationIcon:<IN style={{width:'15px'}}/>
    },{
        title:'Programmer Analyst Trainee',
        location: 'Chennai, Tamil Nadu, India',
        timeline: 'May 2013 - May 2014',
        duration:'1 year 1 month',
        locationIcon:<IN style={{width:'15px'}}/>
    }]
    return(
        <Container data-class="skill-tab-container" disableContentPaddings>
<VerticalTimeline lineColor='#000'>
    {experience.map((exp)=>{return(
        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: '#2E3192', color: '#fff' }}
        contentArrowStyle={{ borderRight: '7px solid  #2E3192' }}
        dateClassName={isDesktop?"date-class":".date-class-mobile"}
        date={exp.timeline}
        iconStyle={{ background: '#2E3192', color: '#000' }}
        icon={<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill='#1BFFFF'><path d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z"></path></svg>}
      >
        <h3 className="vertical-timeline-element-title">{exp.title}</h3>
        <Stack direction={'row'}>
        <h4 className="vertical-timeline-element-subtitle">{exp.location}</h4>
        {exp.locationIcon}
        </Stack>
        <p>
          {exp.duration}
        </p>
      </VerticalTimelineElement>
    )})}
  
</VerticalTimeline>
</Container>
    )
}
