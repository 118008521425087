'use client'

import {
    Heading,
    Avatar,
    Box,
    Text,
    Stack,
    Link,
    Badge,
    useColorModeValue,
    HStack,
    VStack,
    StackDivider,
    Image,
    useBreakpointValue,
} from '@chakra-ui/react'
import { Card, CardBody } from '@chakra-ui/react'
import { ProfileData } from '../data/profile-data'
import { FaBriefcase, FaGlobeAmericas } from 'react-icons/fa'
import { Routes } from '../routes'
import { CA } from 'country-flag-icons/react/3x2'
export default function ProfileWidget() {
    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
      })
    return (
        <Box
            w={'full'}
            h={'full'}
            bg={useColorModeValue('transparent', 'gray.900')}
            
            rounded={'lg'}
            p={6}>
            {isDesktop?
            <HStack >
                <Avatar
                    size={'xl'}
                    src={
                        '/assets/profile-link-pic.jpeg'
                    }
                    mb={4}
                    pos={'relative'}

                />
                <VStack align='stretch'>
                    <Heading justifyItems={'flex-start'} fontSize={'2xl'} fontFamily={'body'}
                    textColor={'#fff'}>
                        {ProfileData.name}
                    </Heading>
                    <HStack>
                        <FaBriefcase color='#fff'/><Text fontSize={'smaller'} fontStyle={'italic'} color={'#1BFFFF'}>Technical Lead </Text>{' | '}
                        <FaGlobeAmericas size={'15'} color='#fff'/> <Text fontSize={'smaller'} fontStyle={'italic'} color={'#1BFFFF'}>Halifax, NS, Canada </Text>
                        <CA style={{width:'15px'}}/>
                        {Routes.map((route) => 
                            (
                            <Link href={route.path} key={route.name} target="_blank" color={'#fff'}>
                               {route.icon}
                            </Link>
                            )
                            )
                    }
                    </HStack>
                </VStack>
            </HStack>
            :<VStack >
            <Avatar
                size={'xl'}
                src={
                    '/assets/profile-link-pic.jpeg'
                }
                mb={4}
                pos={'relative'}

            />
            <VStack align='center'>
                <Heading justifyItems={'flex-start'} fontSize={'2xl'} fontFamily={'body'}
                textColor={'#fff'}>
                    {ProfileData.name}
                </Heading>
                <HStack>
                        <FaBriefcase color='#fff'/><Text fontSize={'smaller'} fontStyle={'italic'} color={'#1BFFFF'}>Technical Lead </Text>{' | '}
                        <FaGlobeAmericas size={'15'} color='#fff'/> <Text fontSize={'smaller'} fontStyle={'italic'} color={'#1BFFFF'}>Halifax, NS, Canada </Text> 
                        <CA style={{width:'15px'}}/>
                        </HStack>
                    <HStack>
                    {Routes.map((route) => 
                            (
                            <Link href={route.path} key={route.name} target="_blank" color={'#fff'}>
                               {route.icon}
                            </Link>
                            )
                            )
                    }
                    </HStack>
            </VStack>
        </VStack>}
        <Stack direction={isDesktop?'row':'column'} mt={2} mb={2}>
                        {ProfileData.topSkills.map((skill) => (
                            <Badge px={2}
                                bg={'#1BFFFF'}
                                color={'#2E3192'}
                                fontWeight={'400'}>{skill}</Badge>
                        ))}
                    </Stack>
            <Text
                textAlign={'left'}
                color={useColorModeValue('#fff', 'gray.400')}
                px={3}
                mb={2}>
                {ProfileData.intro}
            </Text>
            <Card background={'transparent'} shadow={'none'} >


                <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' mb={2} color={'white'}>
                                Opensource Contributions
                            </Heading>
                            <Link color={'#1BFFFF'} href='https://github.com/microsoft/autogen' target='_blank'>microsoft/autogen</Link>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' mb={2} color={'white'}>
                                Featured In
                            </Heading>
                            <VStack align={'flex-start'}>
                                <Link href='https://aws.amazon.com/blogs/apn/generative-ai-assistants-enhance-productivity-and-personalize-the-self-service-employee-experience/' target='_blank' color={'#1BFFFF'}>Gen AI Assistants | APN</Link>
                                <Link href='https://dev.to/makkworld/aws-lambda-performance-optimization-2m76' target='_blank' color={'#1BFFFF'}>Lambda performance | DEV.to</Link>
                            </VStack>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase' mb={2} color={'white'}>
                                Certifications
                            </Heading>
                            {ProfileData.certifications.map((certification, index) => (
                            
                                
                            <Link href={certification.linkToCertification} target='_blank' key={index}>
                                <Image src={certification.logo}/>
                            </Link>
                            ))}
                        </Box>
                    </Stack>
                </CardBody>
            </Card>
        </Box>
    )
}