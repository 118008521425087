'use client'

import {
  Box,
  SimpleGrid,
  useBreakpointValue
} from '@chakra-ui/react'

import ProfileWidget from '../components/ProfileWidget'
import ProfileTabs from '../components/ProfileTabs'
export default function DefaultLayout() {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })
  
  return (
    <Box as="section" height="100vh" overflowY="auto" background={isDesktop?'linear-gradient(to right, #2E3192, #1BFFFF);':'linear-gradient(to bottom, #2E3192, #1BFFFF);'}>
    
    <SimpleGrid columns={isDesktop?2:1} spacing={10} height={'88vh'}>
    <Box as="section" p={3} >
        <ProfileWidget/>
    </Box>
    <Box as="section" >
    <ProfileTabs/>
    </Box>
    </SimpleGrid>
   
     </Box>
  )
}