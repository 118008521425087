import './App.css';
import DefaultLayout from './layout/DefaultLayout';
import { ChakraProvider } from '@chakra-ui/react';

function App() {
  return (
    <ChakraProvider>
    <DefaultLayout/>
    </ChakraProvider>
  );
}

export default App;
